import { useRef } from 'react';
import { css, cx } from 'emotion';
import { getMobileOperatingSystem } from '../brite-viewer/components/handle-action';
import { animation } from '../../shared-components/shared-styles';
import { useStore } from '../store-provider/use-store';
import { PDFComponentContainer } from './PDFComponentContainer';

const pdfRootStyles = `
  width: 100%,
  max-width: 1080px,
  min-width: 1080px,
`;

const briteContentStyles = (rootStyle, os) => {
  return css`
    box-sizing: border-box;
    padding: 8px;
    margin: 0 auto;
    ${rootStyle}
    overflow-x: visible;

    @media only screen and (max-width: 420px) {
      .row.stack-columns {
        flex-direction: column;
        .component-container {
          width: 100%;
        }
      }
    }

    ${os !== 'unknown'
      ? `
    .row.stack-columns {
      flex-direction: column;
      .component-container {
        width: 100%;
      }
    }
    `
      : ''}

    .row {
      z-index: 5;
      page-break-inside: avoid;
      a {
        color: inherit;
        text-decoration: inherit;
      }
    }

    .non-viewable {
      display: none !important;
    }
    opacity: 100%;

    ${animation('fadein', '.35s ease')};
    img {
      ${animation('fadein', '.2s ease')};
    }
  `;
};

const rowStyle = (row) => css`
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  ${!row?.fullScreenWidth
    ? `
      left: 0;
      right: 0;
  `
    : `
    left: calc(100% / 2 - ${window.innerWidth / 2}px);
    width: ${window.innerWidth * 1}px;
  `}
`;

export const PDFPage = ({ page }) => {
  const rowRef = useRef();

  const Content = page?.Content;

  const {
    data: { displaySettings },
  } = useStore();

  const getRowClasslist = (data) => {
    let classList = ['row'];

    if (data?.hideOnMobile && data?.hideOnDesktop) {
      classList.push('non-viewable');
    } else if (data?.hideOnMobile) {
      classList.push('desktop-only');
    } else if (data?.hideOnDesktop) {
      classList.push('mobile-only');
    }

    if (data?.stackColumns) {
      classList.push('stack-columns');
    }

    if (data?.fullScreenWidth) {
      classList.push('full-screen-width');
    }
    return classList.join(' ');
  };

  const os = getMobileOperatingSystem();

  if (!Content || !displaySettings) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: `var(--background-color)`,
        breakAfter: 'page',
        width: 1080, // Matches pdf print width in the guide to pdf service.
        ...(Content?.root?.containerStyle || {}), // Responsible for the background image.
      }}
    >
      <div ref={rowRef} className={cx('_brite-content', briteContentStyles(pdfRootStyles || '', os))}>
        {Content?.rows?.map(
          (row, rowIdx) =>
            !row?.hideOnExport && (
              <div className={getRowClasslist(row)} key={page?.ID + row?.rowId}>
                <div className={rowStyle(row)} style={row?.style || {}} />
                {row?.columns?.map((item, idx) => {
                  return (
                    <PDFComponentContainer
                      item={item}
                      rowIdx={rowIdx}
                      key={page?.ID + row?.rowId + idx}
                      isStackColumns={row?.stackColumns}
                      totalColumns={row?.columns?.length || 0}
                    />
                  );
                })}
              </div>
            )
        )}
      </div>
    </div>
  );
};
