import React, { useState, useEffect, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { fade, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ListSubheader from '@material-ui/core/ListSubheader';
import { toast } from 'react-toastify';
import { MagnifyingGlass } from 'phosphor-react';
import { CustomAxios } from '../axios/axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  search: {
    position: 'relative',
    flex: 1,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    display: 'flex',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    flex: 1,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    flex: 1,
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChatbotPrototypeModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = useState('');
  const [chatBotResponse, setChatBotResponse] = useState('');
  const [filteredStates, setFilteredStates] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleQuery = async () => {
    // query chatbot
    try {
      let resp = await CustomAxios.post(`/public/v1/chat/course/${props.courseId}/message`, {
        Message: query,
      });

      setChatBotResponse(resp.data.Message);
      setFilteredStates(resp.data.RelevantURLs);
    } catch (e) {
      console.log('error querying chatbot', e);
      toast.error('Sorry, we were unable to get an answer to that question.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!query) {
        setFilteredStates([]);
        setChatBotResponse('');
        setLoading(false);
        return;
      }
      setLoading(true);
      handleQuery();
    }, 800);

    return () => clearTimeout(timer);
  }, [query]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setQuery('');
  };

  const decisionToolPageIsAvailable = () => {
    for (let page of props.pages) {
      if (page.Type === 'decision') {
        return true;
      }
    }
    return false;
  };

  const goToRecPage = () => {
    let index = 0;
    for (let page of props.pages) {
      if (page.Type === 'decision') {
        index = page.Order - 1;
      }
    }
    props.setCurrentPageIndex(index);
    setOpen(false);
    setQuery('');
  };

  const formatResponse = (text) => {
    let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    text = text.replace(urlRegex, '<a target="_blank" href="$1">$1</a>');
    text = text.replaceAll('\n', '<br />');
    return text;
  };

  return (
    <div>
      <Tooltip PopperProps={{ style: { zIndex: 234234 } }} title={'Search'}>
        <IconButton color="inherit" aria-label="Search" onClick={handleClickOpen} edge="end">
          <MagnifyingGlass />
        </IconButton>
      </Tooltip>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar} color="transparent">
          <Toolbar>
            <IconButton edge="start" aria-label="Close Search" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>

            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <MagnifyingGlass size={24} />
              </div>
              <InputBase
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Ask a question..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                autoFocus
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Toolbar>
        </AppBar>

        {loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </div>
        )}

        {query && chatBotResponse && (
          <div>
            <Card sx={{ minWidth: 275, maxWidth: 750 }}>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
                  Answer
                </Typography>
                <Typography variant="h5" component="div">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formatResponse(chatBotResponse),
                    }}
                  />
                </Typography>
              </CardContent>
            </Card>
          </div>
        )}
        <List
          subheader={
            <ListSubheader disableSticky={true} component="div" id="nested-list-subheader">
              {!query ? 'No query started' : 'Resources'}
            </ListSubheader>
          }
        >
          {query && filteredStates && filteredStates.length === 0 && (
            <ListItem button onClick={() => {}}>
              <ListItemText classes={{ primary: classes.primary }} primary={'No Results'} />
            </ListItem>
          )}
          {filteredStates.map((relevantURL) => {
            return (
              <React.Fragment key={relevantURL.URL}>
                <ListItem
                  button
                  onClick={() => {
                    setOpen(false);
                    setQuery('');

                    // if relative URL then we need to use the internal page routing
                    if (relevantURL.URL.startsWith('/') && relevantURL.URL.split('/').length >= 3) {
                      // get page number from URL that looks like /page/1
                      let splitURL = relevantURL.URL.split('/');
                      let pageNumStr = splitURL[splitURL.length - 1];
                      let pageNum = parseInt(pageNumStr);
                      props.setCurrentPageIndex(pageNum - 1);
                    } else {
                      // open new tab for external links
                      window.open(relevantURL.URL, '_blank');
                    }
                  }}
                >
                  <ListItemText
                    classes={{ primary: classes.primary }}
                    primary={
                      <>
                        {relevantURL.DisplayName}{' '}
                        {relevantURL.URL.startsWith('/') ? null : <ExitToApp fontSize="small" color="secondary" />}
                      </>
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>

        {decisionToolPageIsAvailable() && (
          <List
            subheader={
              <ListSubheader disableSticky={true} component="div" id="nested-list-subheader">
                Quick Links
              </ListSubheader>
            }
          >
            <ListItem
              button
              onClick={() => {
                goToRecPage();
              }}
            >
              <ListItemText
                classes={{ primary: classes.primary }}
                primary={'Get your recommendations'}
                secondary={'Answer a few simple questions and we will provide personalized recommendations for you.'}
              />
            </ListItem>
          </List>
        )}
      </Dialog>
    </div>
  );
}
