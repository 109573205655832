import { Select as MUISelect } from '@material-ui/core';
import { css, cx } from 'emotion';
import { flex } from './shared-styles';
import { colors } from './styles';
import { Div } from './div';
import { Text, textStyles } from './text';
import { useRef } from 'react';

export const Select = ({ className = '', css: cssStyles = '', children, ...props }) => {
  const anchor = useRef();
  return (
    <Div
      ref={anchor}
      className={cx(
        css`
          position: relative;
          .MuiSelect-root {
            :focus {
              background: none;
            }
            width: 100%;
            outline: none;
            border: none;
            max-height: 100px;
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: ${colors.gray[300]};
          }
          .brite-select {
            border-radius: 8px;
            outline: none;
            border: none;
          }
          background-color: white;
          border-radius: 8px;
          li {
            ${textStyles.body}
          }
        `,
        css`
          ${cssStyles}
        `,
        className
      )}
    >
      {!props?.value && props?.placeholder && (
        <Text
          label
          css={`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin-left: 8px;
            ${flex('left')}
          `}
          ellipsis
        >
          {props.placeholder}
        </Text>
      )}
      <MUISelect
        className="brite-select"
        variant="outlined"
        style={{ width: '100%', height: '44px', ...(props?.style || {}) }}
        keepMounted
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          style: { maxHeight: '500px', zIndex: '100000000' },
          getContentAnchorEl: null,
        }}
        {...props}
      >
        {children}
      </MUISelect>
    </Div>
  );
};
