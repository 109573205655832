import { css } from 'emotion';
import { Div, Text } from '../../../shared-components';
import { flex } from '../../../shared-components/shared-styles';
import { colors } from '../../../shared-components/styles';
import { FieldComponent } from '../../brite-viewer/components/benefits/field-component';

export const SummaryField = ({ product, field, config, comparisonView = false }) => {
  // Field is not defined at some point in the render cycle so we need to check for it before proceeding.
  if (!field || (!comparisonView && field.State === 'hide') || config?.hideField) {
    return null;
  }

  const hasSectionTitle = 'sectionTitle' in field;

  const Wrapper = ({ children }) => {
    return (
      <Div
        css={css`
          overflow: hidden;
          ${comparisonView && field?.State === 'hide'}
          padding: 8px 24px;
          ${field?.State === 'highlight'
            ? `
            border-left: 4px solid var(--button-background-color, ${colors.purple});
            p {
              font-size: 1.3em;
              font-weight: bold;
            }
          `
            : ''}
        `}
      >
        {children}
      </Div>
    );
  };

  if (config?.hideField) {
    return null;
  } else if (config?.components?.length) {
    return (
      <>
        {config?.components?.map((Component) => (
          <Wrapper>
            <Component field={field} Wrapper={Wrapper} />
          </Wrapper>
        ))}
      </>
    );
  } else if (hasSectionTitle) {
    return (
      <Text
        h4
        className={css`
          padding: 16px 0;
          width: 100%;
        `}
      >
        {field.sectionTitle}
      </Text>
    );
  } else if (field.Type === 'label') {
    return (
      <Text
        h4
        bold={product?.Type === 'insurance_plan'}
        className={css`
          padding: 16px 0;
          width: 100%;
        `}
      >
        {field.RecDisplayValue || field.DisplayValue}
      </Text>
    );
  }

  return (
    <Wrapper>
      {!hasSectionTitle ? (
        <Div
          css={css`
            ${!(field.RecDisplayValue || field?.DisplayValue)
              ? ''
              : comparisonView
              ? ''
              : flex('space-between start')} flex-grow: 1;
          `}
        >
          <Text label>{field.RecDisplayValue || field.DisplayValue}</Text>
          {field.Type !== 'label' && <FieldComponent field={field} product={product} comparisonView={comparisonView} />}
        </Div>
      ) : null}
    </Wrapper>
  );
};
