import { css } from 'emotion';
import React from 'react';
import { ExtensionIconMapper } from '../../../shared-components/extension-icon-mapper';
import { colors } from '../../../shared-components/styles';
import { getAttributes } from '../utils';

export const Document = ({ item, totalColumns }) => {
  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: 'component',
  });

  return (
    <div {...componentAttributes} style={{ width: '100%', boxSizing: 'border-box' }}>
      <a
        target="_blank"
        href={item?.document?.PublicURL}
        className={css`
          display: flex;
          align-items: center;
          justify-content: start;
          background-color: ${colors.gray[100]};
          border-radius: 8px;
          width: 100%;
          height: 64px;
          text-decoration: none;
          cursor: pointer;
        `}
      >
        <ExtensionIconMapper
          className={css`
            padding-left: 16px;
            min-width: 32px;
          `}
          fileType={item?.document?.FileType}
        />
        <p
          className={css`
            font-size: 18px;
            color: ${colors.black};
            margin-left: 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          `}
        >
          {item?.document?.Name}
        </p>
      </a>
    </div>
  );
};
